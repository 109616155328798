<template>
    <v-snackbar
        color="success"
        v-model="showOnlineMessage"
        :timeout="2000"
        :bottom:="50"
        center>
        <small class="py-3">
            {{$t("online_status.online")}}
        </small>
    </v-snackbar>
</template>

<script>
export default {
    name: 'isOnlineMessage',
    data: () => ({
        showOnlineMessage: true
    })
};
</script>
