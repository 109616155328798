<template>
    <v-snackbar
        style="max-width: 80%; margin:0 auto;"
        v-model="showOfflineMessage"
        color="warning"
        :bottom:="50"
        multi-line
        :timeout="-1"
        center>
        <small class="py-3">
            {{$t("online_status.message_offline_1")}}
            <br />{{$t('online_status.message_offline_2')}}
            <br />
            <v-btn small outlined rounded color="white" class="ml-0 mt-2" @click.native="closeOfflineMessage">{{$t("online_status.close")}}</v-btn>
        </small>
    </v-snackbar>
</template>

<script>
export default {
    name: 'IsOfflineMessage',
    data: () => ({
        showOfflineMessage: true
    }),
    methods: {
        closeOfflineMessage() {
            this.showOfflineMessage = false;
        }
    }
};
</script>
