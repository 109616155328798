import Dexie from 'dexie';

const db = new Dexie('surveyDB');
db.version(1).stores({
    surveys: 'id++',
    pendingSurveys: 'id',
    pendingSurveyFromServer: 'id',
    completedSurveys: 'id',
    notifications: 'id',
    staticPages: 'id'
});

export default db;
