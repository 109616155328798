<template>
    <div class="app-view">
        <v-container class="pt-0 mt-0">
            <div class="text-center py-3">
                <v-icon>
                    sentiment_very_dissatisfied
                </v-icon>
                <h2>{{$t('page404.header')}}</h2>
                <p>{{$t('page404.body')}}</p>
                <v-btn color="primary" :to="{'name': 'signin'}" rounded depressed>{{$t('page404.btn')}}</v-btn>
            </div>
        </v-container>
    </div>
</template>

<script>
export default {
    name: 'PageNotFound'
};
</script>
