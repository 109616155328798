export const IsBetween = (value, min, max) => {
    const minValue = Math.min(min, max);
    const maxValue = Math.max(min, max);
    return value > minValue && value < maxValue;
};

export const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.addEventListener("load", () => {
            resolve(reader.result);
        });
        reader.addEventListener("error", (error) => {
            reject(error);
        });
    });
};

// *********** Upload file to Cloudinary ******************** //
// https://cloudinary.com/documentation/image_upload_api_reference
// https://cloudinary.com/documentation/upload_images
// https://cloudinary.com/documentation/admin_api#upload_presets
// https://cloudinary.com/documentation/upload_images#upload_presets
// https://cloudinary.com/documentation/image_upload_api_reference#upload_method
// https://codepen.io/team/Cloudinary/pen/QgpyOK
// http://seanabrahams.com/cloudinary/upload/js/javascript/plain/2016/05/16/uploading-directly-to-cloudinary-using-plain-js.html

export const uploadFileToCloudinary = (file, payload, n) => {
    return new Promise(function (resolve, reject) {
        const CLOUDINARY_URL =
            "https://api.cloudinary.com/v1_1/survey-associasion/upload";
        const CLOUDINARY_UPLOAD_PRESET = "v4mk0udz";

        const formData = new FormData();
        formData.append("upload_preset", CLOUDINARY_UPLOAD_PRESET);
        formData.append("tags", payload.answerId);
        formData.append("folder", payload.path);
        formData.append("file", file);

        const request = new XMLHttpRequest();
        request.open("POST", CLOUDINARY_URL, true);
        request.setRequestHeader("X-Requested-With", "XMLHttpRequest");

        request.onreadystatechange = () => {
            if (request.readyState === 4 && request.status === 200) {
                // File uploaded successfully
                const response = JSON.parse(request.responseText);
                return resolve(response);
            }
            if (request.status !== 200) {
                let errorMessage = null;
                try {
                    const response = JSON.parse(request.responseText);
                    errorMessage = response.error.message;
                } catch (error) {
                    console.error(error, request);
                    errorMessage = error.message;
                }
                console.error(
                    `Can't upload file to cloudinary, not OK statuscode (${request.status}) for upload: ${errorMessage}`
                );
                return reject(
                    `Can't upload file to cloudinary, not OK statuscode (${request.status}) for upload: ${errorMessage}`
                );
            }
        };

        request.onerror = (err) => {
            if (n === 1) {
                alert("error uploading file to cloudinary " + err);
                return reject(err);
            }

            uploadFileToCloudinary(file, payload, n - 1)
                .then(resolve)
                .catch((err) => {
                    reject(err);
                });
        };

        request.send(formData);
    });
};

export const generateUniqueID = () => {
    return Math.random().toString(36).substr(2, 9);
};

export const isIos = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test(userAgent);
};

// Not future proof - TODO: Refactor when IOS has a viable solution
export const isIpad = () => {
    if (
        navigator.userAgent.match(/Mac/) &&
        navigator.maxTouchPoints &&
        navigator.maxTouchPoints > 2
    ) {
        return true;
    }
};

// Detects if device is in standalone mode
export const isInStandaloneMode = () =>
    "standalone" in window.navigator && window.navigator.standalone;

export const shouldShowInstallPrompts = () => {
    const href = window.location.href;

    return !(href.includes("emailValidate") || href.includes("passswordReset"));
};
