import Vue from "vue";
import Router from "vue-router";
import SignIn from "./views/SignIn.vue";
import store from "./store/index";
import PageNotFound from "./views/PageNotFound";
import goTo from "vuetify/es5/services/goto";
Vue.use(Router);

function guard(to, from, next) {
    const userInStorage = localStorage.getItem("AUTH_TOKEN");
    if (store.state.users.currentUser || userInStorage) {
        next(); // allow to enter route
    } else {
        next("/"); // go to '/login';
    }
}

export default new Router({
    mode: "hash",
    base: process.env.BASE_URL,
    scrollBehavior: (to, from, savedPosition) => {
        let scrollTo = 0;

        if (to.hash) {
            scrollTo = to.hash;
        } else if (savedPosition) {
            scrollTo = savedPosition.y;
        }

        return goTo(scrollTo);
    },
    routes: [
        {
            path: "/",
            name: "signin",
            component: SignIn,
        },
        {
            path: "/passwordReset/:tokenId/:email",
            name: "reset-password",
            component: () =>
                import(
                    /* webpackChunkName: "password-reset" */ "./views/PasswordReset"
                ),
        },
        {
            path: "/emailValidate/:tokenId/:email",
            name: "email-validate",
            component: () =>
                import(
                    /* webpackChunkName: "email-validate" */ "./views/EmailValidate"
                ),
        },
        {
            path: "/request-access",
            name: "request-access",
            meta: { transitionName: "slide" },
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () =>
                import(
                    /* webpackChunkName: "request-access" */ "./views/RequestAccess"
                ),
        },
        {
            path: "/home",
            beforeEnter: guard,
            meta: { transitionName: "slide" },
            component: () =>
                import(/* webpackChunkName: "home" */ "./views/App/Home.vue"),
            children: [
                {
                    path: "",
                    name: "dashboard",
                    meta: { transitionName: "slide" },
                    component: () =>
                        import(
                            /* webpackChunkName: "dashboard" */ "./views/App/Dashboard/Dashboard"
                        ),
                },
                {
                    path: "/completed",
                    name: "completed",
                    meta: { transitionName: "slide" },
                    component: () =>
                        import(
                            /* webpackChunkName: "surveys-completed" */ "./views/App/Surveys/Completed/SurveysCompleted"
                        ),
                },
                {
                    path: "/drafts",
                    name: "drafts",
                    meta: { transitionName: "slide" },
                    component: () =>
                        import(
                            /* webpackChunkName: "surveys-pending" */ "./views/App/Surveys/Drafts/SurveysDrafts"
                        ),
                },
                {
                    path: "/pending",
                    name: "pending",
                    meta: { transitionName: "slide" },
                    component: () =>
                        import(
                            /* webpackChunkName: "surveys-pending" */ "./views/App/Surveys/Pending/SurveysPending"
                        ),
                },
                {
                    path: "/notifications",
                    meta: { transitionName: "slide" },
                    component: () =>
                        import(
                            /* webpackChunkName: "notifications" */ "./views/App/Notifications/Notifications"
                        ),
                    children: [
                        {
                            path: "",
                            name: "notification-list",
                            meta: { transitionName: "slide" },
                            component: () =>
                                import(
                                    /* webpackChunkName: "notifications" */ "./components/Notifications/NotificationList"
                                ),
                        },
                        {
                            path: ":id",
                            name: "notification-details",
                            meta: { transitionName: "slide" },
                            component: () =>
                                import(
                                    /* webpackChunkName: "notifications" */ "./components/Notifications/NotificationDetail"
                                ),
                        },
                    ],
                },
            ],
        },
        {
            path: "/settings",
            beforeEnter: guard,
            name: "settings",
            meta: { transitionName: "slide" },
            component: () =>
                import(
                    /* webpackChunkName: "profile" */ "./views/App/Profile/Profile"
                ),
        },
        {
            path: "/survey/:surveyId",
            beforeEnter: guard,
            name: "survey-detail",
            props: true,
            meta: { transitionName: "slide" },
            component: () =>
                import(
                    /* webpackChunkName: "survey" */ "./views/App/Surveys/Survey"
                ),
        },
        {
            path: "/survey/view/:surveyId",
            beforeEnter: guard,
            name: "survey-view",
            props: true,
            meta: { transitionName: "slide" },
            component: () =>
                import(
                    /* webpackChunkName: "survey-view" */ "./views/App/Surveys/SurveyView"
                ),
        },
        {
            path: "/survey-instance/:surveyId",
            beforeEnter: guard,
            name: "survey-instance",
            props: true,
            meta: { transitionName: "slide" },
            component: () =>
                import(
                    /* webpackChunkName: "survey-instance" */ "./views/App/Surveys/SurveyInstance"
                ),
        },
        {
            path: "/static/:id",
            beforeEnter: guard,
            name: "static-detail",
            meta: { transitionName: "slide" },
            component: () =>
                import(
                    /* webpackChunkName: "static" */ "./views/App/Static/StaticDetail"
                ),
        },
        { path: "*", component: PageNotFound },
    ],
});
