import gql from "graphql-tag";
import { LocalizedInputFragment } from "@/graphql/fragtments/localizedInput";

export const userRoleRefresh = gql`
    query userRoleUpdate {
        user {
            id
            firstName
            lastName
            email
            ship
            imo
            locale
            roleName
            permissionRole {
                roleName
                title {
                    ...localizedFragment
                }
                permissions
            }
        }
    }
    ${LocalizedInputFragment}
`;
