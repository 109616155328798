import {apolloClient} from '../../apollo/apollo';
import {setThemeStorage} from '../../helpers/themeHelpers';
import {getAllCompaniesFromClient, getCurrentClient} from '../../graphql/queries/clients';

export const state = {
    currentClient: null
};

export const getters = {};

export const mutations = {
    setCurrentClient(state, data) {
        state.currentClient = data;
    },
    setCurrentClientInStorage(state, data) {
        setThemeStorage(data);
    }
};

export const actions = {
    async getCurrentClient({commit}) {
        const {data} = await apolloClient.query({
            query: getCurrentClient,
            fetchPolicy: 'no-cache'
        });

        try {
            commit('setCurrentClient', data.client);
            commit('setCurrentClientInStorage', data.client);
            return data;
        } catch (e) {
            console.error('error in getClient', e);
            return e;
        }
    },
    async getAllCompanies({commit}) {
        const {data} = await apolloClient.query({
            query: getAllCompaniesFromClient,
            fetchPolicy: 'no-cache'
        });

        try {
            return data.companies;
        } catch (e) {
            console.error('cant get companies', e);
            return e;
        }
    }
};

export default {
    state,
    mutations,
    actions,
    getters
};
