import {apolloClient} from '../../apollo/apollo';
import {getStaticPageByID, getStaticPages} from '../../graphql/queries/static';
import {getStaticPagesFromIndexedDB, saveStaticPagesIndexedDB} from '../../helpers/indexedDB';

export const state = {
    staticPages: [],
    staticPage: null
};

export const getters = {
};

export const mutations = {
    setStaticPage(state, page) {
        state.staticPage = page;
    },
    setStaticPages(state, pages) {
        state.staticPages = pages;
        saveStaticPagesIndexedDB(pages);
    },
    setStaticPageFromStorage(state, id) {
        const page = state.staticPages.find((page) => page.id === id);
        state.staticPage = page;
    }
};

export const actions = {
    async getAllPages({commit}) {
        const {data} = await apolloClient.query({
            query: getStaticPages,
            fetchPolicy: 'no-cache'
        });
        try {
            commit('setStaticPages', data.staticPages);
        } catch (e) {
            console.error('cant get static pages', e);
            return e;
        }
    },
    async getPageById({commit}, id) {
        const {data} = await apolloClient.query({
            query: getStaticPageByID,
            fetchPolicy: 'no-cache',
            variables: {
                id: id
            }
        });

        try {
            commit('setStaticPage', data.staticPage);
        } catch (e) {
            console.error('cant get page by id', e);
            return e;
        }
    },
    async getStaticPagesFromIndexedDB({commit}) {
        const response = await getStaticPagesFromIndexedDB();

        try {
            console.log('pages', response[0]);
            const pages = response[0].pages;
            commit('setStaticPages', pages);
        } catch (e) {
            console.error('cant get pages from indexedDB', e);
            return e;
        }
    }
};

export default {
    state,
    mutations,
    actions,
    getters
};
