import constants from "./constants";

function setAttributeOnManifest(manifest, name) {
    manifest.setAttribute("href", "/" + name + "-manifest.json");
}

function setAppleTouchIcon(element, client) {
    element.setAttribute(
        "href",
        "/img/" + client + "-icons/apple-touch-icon.png"
    );
}

function setAppleStartupImage(element, client) {
    element.setAttribute("href", "/img/" + client + "-icons/splash.png");
}

export const setPathToManifestFile = () => {
    const host = window.location.hostname;
    const manifest = document.querySelector('link[rel="manifest"]');

    if (host.includes(constants.clientHostNames.tokiomarine)) {
        return setAttributeOnManifest(manifest, "tokiomarine");
    }
    if (host.includes(constants.clientHostNames.sac)) {
        return setAttributeOnManifest(manifest, "sac");
    }
    if (host.includes(constants.clientHostNames.saremote)) {
        return setAttributeOnManifest(manifest, "saremote");
    }
    if (host.includes(constants.clientHostNames.insurwave)) {
        return setAttributeOnManifest(manifest, "insurwave");
    }
    if (host.includes(constants.clientHostNames.netlify)) {
        return setAttributeOnManifest(manifest, "staging");
    }
    if (host.includes(constants.clientHostNames.test)) {
        return setAttributeOnManifest(manifest, "localhost");
    }
    let subdomain = host.split(".").slice(0, -2).join(".");
    return setAttributeOnManifest(manifest, subdomain);
};

export const setAppIcon = () => {
    const host = window.location.hostname;
    const selectedElement = document.querySelector(
        'link[rel="apple-touch-icon"]'
    );

    if (host.includes(constants.clientHostNames.tokiomarine)) {
        return setAppleTouchIcon(selectedElement, "tokiomarine");
    }
    if (
        host.includes(
            constants.clientHostNames.localhost ||
                constants.clientHostNames.sac ||
                constants.clientHostNames.sa
        )
    ) {
        return setAppleTouchIcon(selectedElement, "localhost");
    }
    if (host.includes(constants.clientHostNames.insurwave)) {
        return setAppleTouchIcon(selectedElement, "insurwave");
    }
    if (host.includes(constants.clientHostNames.sac)) {
        return setAppleTouchIcon(selectedElement, "localhost");
    }
    let subdomain = host.split(".").slice(0, -2).join(".");

    return setAppleTouchIcon(selectedElement, subdomain);
};

export const setStartupImage = () => {
    const host = window.location.hostname;
    const selectedElement = document.querySelector(
        'link[rel="apple-touch-startup-image"]'
    );
    let subdomain = host.split(".").slice(0, -2).join(".");

    if (host.includes(constants.clientHostNames.tokiomarine)) {
        return setAppleStartupImage(selectedElement, "tokiomarine");
    }
    if (
        host.includes(
            constants.clientHostNames.localhost || constants.clientHostNames.sac
        )
    ) {
        return setAppleStartupImage(selectedElement, "localhost");
    }
    if (host.includes(constants.clientHostNames.insurwave)) {
        return setAppleStartupImage(selectedElement, "insurwave");
    }
    return setAppleStartupImage(selectedElement, subdomain);
};
