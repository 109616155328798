import gql from "graphql-tag";
import { LocalizedInputFragment } from "@/graphql/fragtments/localizedInput";

export const userSignUpMutation = gql`
    mutation userSignup(
        $email: String!
        $companyId: String!
        $firstName: String!
        $lastName: String!
        $jobTitle: String!
        $password: String!
        $languages: [String!]
        $locale: String
    ) {
        userSignup(
            email: $email
            companyId: $companyId
            firstName: $firstName
            lastName: $lastName
            jobTitle: $jobTitle
            password: $password
            languages: $languages
            locale: $locale
        ) {
            success
            message
        }
    }
`;

export const userForgotPassword = gql`
    mutation userForgotPassword($email: String!) {
        userForgotPassword(email: $email) {
            success
            message
        }
    }
`;

export const userResetPassword = gql`
    mutation userResetPassword(
        $email: String!
        $token: String!
        $password: String!
    ) {
        userResetPassword(email: $email, token: $token, password: $password) {
            success
            message
        }
    }
`;

export const userVerifyEmail = gql`
    mutation userVerifyEmail($email: String!, $token: String!) {
        userVerifyEmail(email: $email, token: $token) {
            success
            message
        }
    }
`;

export const userLogin = gql`
    mutation userLogin($email: String!, $password: String!) {
        userLogin(email: $email, password: $password) {
            success
            message
            user {
                id
                firstName
                lastName
                email
                ship
                imo
                locale
                roleName
                permissionRole {
                    roleName
                    title {
                        ...localizedFragment
                    }
                    permissions
                }
            }
        }
    }
    ${LocalizedInputFragment}
`;

export const updateUserPassword = gql`
    mutation userChangePassword(
        $email: String!
        $oldPassword: String!
        $newPassword: String!
    ) {
        userChangePassword(
            email: $email
            oldPassword: $oldPassword
            newPassword: $newPassword
        ) {
            success
            message
        }
    }
`;

export const updateUserLocale = gql`
    mutation userUpdateLocale($locale: String!) {
        userUpdateLocale(locale: $locale) {
            success
            message
            user {
                id
                firstName
                lastName
                email
                ship
                imo
                locale
            }
        }
    }
`;

export const deleteUser = gql`
    mutation userDelete($id: ID!) {
        userDelete(id: $id) {
            success
            message
        }
    }
`;
