import gql from 'graphql-tag';
import {LocalizedInputFragment} from '@/graphql/fragtments/localizedInput';

export const getSurveys = gql`
    query surveys {
        surveys {
            id
            icon
            isActive
            category {
                id
                name {
                    ...localizedFragment
                }
            }
            priority
            version
            type
            title {
                ...localizedFragment
            }
        }
    }
    ${LocalizedInputFragment}
`;

export const getSurveyById = gql`
    query survey($id: ID!) {
        survey(id: $id) {
            id
            type
            title {
                ...localizedFragment
            }
            description {
                ...localizedFragment
            }
            isActive
            icon
            ship
            children {
                id
                title {
                    ...localizedFragment
                }
                description {
                    ...localizedFragment
                }
                roleName
                displayConditions {
                    id
                    conditionQuestionId
                    operator
                    value
                    conditionQuestionInputType
                }
                hasConditions
                children {
                    id
                    title {
                        ...localizedFragment
                    }
                    description {
                        ...localizedFragment
                    }
                    roleName
                    displayConditions {
                        id
                        conditionQuestionId
                        operator
                        value
                        conditionQuestionInputType
                    }
                    hasConditions
                    children {
                        id
                        inputType
                        question {
                            ...localizedFragment
                        }
                        helpShort {
                            ...localizedFragment
                        }
                        helpDetail {
                            ...localizedFragment
                        }
                        priority
                        title {
                            ...localizedFragment
                        }
                        question {
                            ...localizedFragment
                        }
                        isRequired
                        min
                        max
                        confirmText {
                            ...localizedFragment
                        }
                        confirmUrl
                        placeholder {
                            ...localizedFragment
                        }
                        isVisible
                        isHighlighted
                        hasConditions
                        roleName
                        data
                        displayConditions {
                            id
                            conditionQuestionId
                            operator
                            value
                            conditionQuestionInputType
                        }
                        options {
                            id
                            option {
                                ...localizedFragment
                            }
                        }
                    }
                }
            }
        }
    }
    ${LocalizedInputFragment}
`;

export const getSurveyByVersion = gql`
    query surveyVersion($id: ID!, $version: Int!) {
        surveyVersion(id: $id, version: $version)
    }
`;

export const getSurveyInstancesByType = gql`
    query surveyInstances($type: String!) {
        surveyInstances(type: $type) {
            id
            instanceId
            title {
                ...localizedFragment
            }
            createdAt
            updatedAt
            status
            surveyVersion
            version
            surveyId
            ship
            imo
        }
    }
    ${LocalizedInputFragment}
`;

export const getSurveyInstanceVersion = gql`
    query surveyInstanceVersion($instanceId: ID!, $version: Int!) {
        surveyInstanceVersion(instanceId: $instanceId, version: $version) {
            survey
            instanceId
            instanceVersion
        }
    }
`;

export const getSurveyInstance = gql`
    query surveyInstance($id: ID!) {
        surveyInstance(id: $id) {
            id
            surveyVersion
            title {
                ...localizedFragment
            }
            version
        }
    }
    ${LocalizedInputFragment}
`;
