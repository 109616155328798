import Rollbar from 'rollbar';
import {apolloProvider} from './apollo/apollo';
import Vue from 'vue';
import VeeValidate from 'vee-validate';
import vuetify from './plugins/vuetify';
import router from './router';
import store from './store/index';
import App from './App.vue';
import './registerServiceWorker';
import {setPathToManifestFile, setAppIcon, setStartupImage} from './helpers/pwa';

/* COMPONENTS */
import 'vuetify-datetime-picker/src/stylus/main.styl';
import DatetimePicker from 'vuetify-datetime-picker';
import Loading from './components/UI/Loading';
/* MIXINS */
import OnlineStatus from './mixins/OnlineStatus';
/* FILTERS */
import formatStatus from './filters/format-status';
import formatDateAlternate from './filters/format-date-alternate';
import formatDatePendingLocal from './filters/format-date-pending-local';
import formatDate from './filters/format-date';
import formatDateWithTime from './filters/format-date-with-time';
import i18n from './i18n';
import FlagIcon from 'vue-flag-icon';
import {createMixin} from './mixins/ScreenWidthAndHeight';
// https://bugs.webkit.org/show_bug.cgi?id=226547
window.indexedDB.open('dummy', 1);
// Set the Rollbar instance in the Vue prototype
// before creating the first Vue instance.
// This ensures it is available in the same way for every
// instance in your app.
if (process.env.VUE_APP_ROLLBAR_TOKEN) {
    let rollbarClient = null;
    if (process.env.VUE_APP_COMMIT_REF) {
        rollbarClient = {
            javascript: {
                source_map_enabled: true, // true by default

                // -- Add this into your configuration ---
                code_version: process.env.VUE_APP_COMMIT_REF || '0.0.0',
                // ---------------------------------------

                // Optionally have Rollbar guess which frames the error was
                // thrown from when the browser does not provide line
                // and column numbers.
                guess_uncaught_frames: true
            }
        };
    }
    window.rollbar = new Rollbar({
        accessToken: process.env.VUE_APP_ROLLBAR_TOKEN,
        captureUncaught: true,
        captureUnhandledRejections: true,
        payload: {
            environment: process.env.NODE_ENV,
            client: rollbarClient
        }
    });
    Vue.prototype.$rollbar = window.rollbar;

    // If you have already set up a global error handler,
    // just add `vm.$rollbar.error(err)` to the top of it.
    // If not, this simple example will preserve the app’s existing
    // behavior while also reporting uncaught errors to Rollbar.
    Vue.config.errorHandler = (err, vm, info) => {
        vm.$rollbar.error(err);
        throw err; // rethrow
    };
}

Vue.use(FlagIcon);
Vue.use(DatetimePicker);

Vue.mixin(OnlineStatus);
Vue.mixin(createMixin(window));
// Global components
Vue.component('Loading', Loading);

Vue.filter('formatDate', formatDate);
Vue.filter('formatDateAlternate', formatDateAlternate);
Vue.filter('formatDatePendingLocal', formatDatePendingLocal);
Vue.filter('formatStatus', formatStatus);
Vue.filter('formatDateWithTime', formatDateWithTime);

Vue.use(VeeValidate);

setPathToManifestFile();
setAppIcon();
setStartupImage();

async function isStoragePersisted() {
    return navigator.storage?.persisted?.();
}

async function persist() {
    return navigator.storage?.persist?.();
}

isStoragePersisted().then(async isPersisted => {
    if (isPersisted) {
        console.log(':) Storage is successfully persisted.');
    } else {
        console.log(':( Storage is not persisted.');
        console.log('Trying to persist..:');
        if (await persist()) {
            console.log(':) We successfully turned the storage to be persisted.');
        } else {
            console.log(':( Failed to make storage persisted');
        }
    }
});

router.beforeEach((to, from, next) => {
    const AUTH_TOKEN = localStorage.getItem('AUTH_TOKEN');
    const USER_ID = localStorage.getItem('USER_ID');
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (AUTH_TOKEN === null) {
            next({
                path: '/',
                params: {nextUrl: to.fullPath}
            });
        } else {
            if (USER_ID && AUTH_TOKEN) {
                next();
            }
        }
    } else {
        next();
    }
});

Vue.config.productionTip = false;
window.i18n = i18n;
new Vue({
    router,
    store,
    apolloProvider,
    vuetify,
    i18n,

    render:
        h => h(App)
}).$mount('#app');
