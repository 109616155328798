import gql from 'graphql-tag';
import {LocalizedInputFragment} from '@/graphql/fragtments/localizedInput';

export const submitSurvey = gql`
    mutation surveySubmit($id: ID!, $version: Int!, $answers: String!, $instanceId: ID, $instanceVersion: Int, $locale: String!) {
        surveySubmit(
            id: $id,
            version: $version,
            answers: $answers,
            instanceId: $instanceId,
            instanceVersion: $instanceVersion,
            locale: $locale
        ) {
            success,
            message,
            errors {
                question {
                    ... localizedFragment
                }
                questionId
                error
                value
            },
            uploads {
                questionId
                answerId
                optionId
                file
                fileType
                path
            }
        }
    }
    ${LocalizedInputFragment}
`;

export const submitAllFilesToSurvey = gql`
    mutation surveyInstanceFileSubmit($instanceId: ID!, $answerId: ID!, $fileName: String!, $fileType: String!, $optionId: ID) {
        surveyInstanceFileSubmit(
            instanceId: $instanceId,
            answerId: $answerId,
            fileName: $fileName,
            fileType: $fileType,
            optionId: $optionId
        ) {
            success,
            message
        }
    }
`;
