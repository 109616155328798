import Vue from 'vue';
import Vuex from 'vuex';
// import VuexPersist from 'vuex-persist'; // https://alligator.io/vuejs/vuex-persist-state/
import users from './modules/users';
import client from './modules/client';
import modals from './modules/modals';
import surveys from './modules/surveys';
import notifications from './modules/notifications';
import staticPages from './modules/static';
import i18n from '@/i18n';
Vue.use(Vuex);
/*
const vuexLocalStorage = new VuexPersist({
    key: 'vuex', // The key to store the state on in the storage provider.
    storage: window.localStorage, // or window.sessionStorage or localForage
    reducer: state => ({
        users: state.users,
        client: state.client,
        //surveys: state.surveys
    })
    // Function that passes the state and returns the state with only the objects you want to store.
    // reducer: state => state,
    // Function that passes a mutation and lets you decide if it should update the state in localStorage.
    // filter: mutation => (true)
}) */

const store = new Vuex.Store({
    state: {
        locale: 'en'
    },
    mutations: {
        changeLanguage(state, language) {
            state.locale = language;
            i18n.locale = language;
            window?.localStorage?.setItem('locale', language);
        },
    },
    modules: {
        users: {...users, namespaced: true},
        modals: {...modals, namespaced: true},
        client: {...client, namespaced: true},
        surveys: {...surveys, namespaced: true},
        notifications: {...notifications, namespaced: true},
        staticPages: {...staticPages, namespaced: true}
    },
    // plugins: [vuexLocalStorage.plugin],
    strict: false
});

export default store;
