const constants = {
    screenSizes: {
        xs: 480,
        sm: 768,
        md: 1024,
        lg: 1240,
        xl: 1440
    },
    cloudinaryUrl: 'https://res.cloudinary.com/survey-associasion/image/upload/v1568719474/', // See docs: https://cloudinary.com/documentation/upload_widget#api_events
    cloudinaryUrlSmall: 'https://res.cloudinary.com/survey-associasion/image/upload/w_80/v1568719474/',
    cloudinaryUrlSurveyIcons: 'https://res.cloudinary.com/survey-associasion/image/upload/w_450/v1568719474/',
    cloudinaryVideo: 'https://res.cloudinary.com/survey-associasion/video/upload/',
    clientHostNames: {
        localhost: 'localhost',
        saremote: 'sac',
        athena: 'athena',
        tokiomarine: 'tokiomarine',
        fairway: 'fairway\.ca',
        insurwave: 'insurwave',
        // sac: 'sac'
        test: '.test',
        netlify: 'mystifying-jennings-6880a1.netlify.app'
    }
};

export default constants;
