import constants from "./constants";
import apolloConfig from "../apollo.config";

export const clientIdBasedOnHostname = (host) => {
    let clientId = null;
    // if (host.includes(constants.clientHostNames.localhost)) {
    //     clientId = apolloConfig.clientId;
    // }
    if (host.includes(constants.clientHostNames.test)) {
        clientId = apolloConfig.clientIdTest;
    }
    if (host.includes(constants.clientHostNames.athena)) {
        clientId = apolloConfig.clientId;
    }
    if (host.includes(constants.clientHostNames.tokiomarine)) {
        clientId = apolloConfig.clientIdTokioMarine;
    }
    if (host.includes(constants.clientHostNames.insurwave)) {
        clientId = apolloConfig.clientIdInsurwave;
    }
    /* if (host.includes(constants.clientHostNames.sac)) {
        clientId = apolloConfig.clientId;
    } */
    if (host.includes(constants.clientHostNames.saremote)) {
        clientId = apolloConfig.clientIdSAC;
    }
    if (host.includes(constants.clientHostNames.fairway)) {
        clientId = apolloConfig.clientIdFairway;
    }
    if (host.includes(constants.clientHostNames.netlify)) {
        clientId = apolloConfig.clientIdTest;
    }
    return clientId;
};

export const chooseDBasedOnHostname = (host) => {
    let db = apolloConfig.envUrls.production;
    if (host.includes(constants.clientHostNames.test)) {
        db = apolloConfig.envUrls.test;
    }
    if (host.includes(constants.clientHostNames.localhost)) {
        db = apolloConfig.envUrls.local;
    }

    if (host.includes(constants.clientHostNames.netlify)) {
        db = apolloConfig.envUrls.staging;
    }
    return db;
};
