<template>
    <v-snackbar
        v-model="showRefreshBar"
        :timeout="timeout"
        bottom
        center>
        {{ $t("pwa.new_version") }}

        <v-spacer />
        <v-btn
            text
            color="white"
            dark
            @click.native="refreshApp">
            Update now
        </v-btn>
        <v-btn
            color="white"
            icon
            @click="showRefreshBar = false">
            <v-icon>close</v-icon>
        </v-btn>
    </v-snackbar>
</template>

<script>
export default {
    name: "RefreshApp",
    data: () => ({
        refreshing: false,
        registration: null,
        showRefreshBar: false,
        timeout: -1
    }),
    methods: {
        forceSWupdate() {
            if ("serviceWorker" in navigator) {
                navigator.serviceWorker.getRegistrations().then((registrations) => {
                    for (const registration of registrations) {
                        registration.update();
                    }
                });
            }
        },
        showRefreshApp(e) {
            // Display a snackbar inviting the user to refresh/reload the app due
            // to an app update being available.
            // The new service worker is installed, but not yet active.
            // Store the ServiceWorkerRegistration instance for later use.
            this.registration = e.detail;
            this.showRefreshBar = true;
        },
        refreshApp() {
            this.showRefreshBar = false;
            // Protect against missing registration.waiting.
            if (!this.registration || !this.registration.waiting) {
                return;
            }

            this.registration.waiting.postMessage("skipWaiting");
            this.forceSWupdate();
            // Reload the page and ignore the browser cache.
            window.location.reload(true);
        }
    },
    created() {
        // Listen for swUpdated event and display refresh snackbar as required.
        document.addEventListener("swUpdated", this.showRefreshApp);
        // Refresh all open app tabs when a new service worker is installed.
        if ("serviceWorker" in navigator) {
            navigator.serviceWorker.addEventListener("controllerchange", () => {
                if (this.refreshing) {
                    return;
                }
                window.location.reload(true);
                this.refreshing = true;
            });
        }
    },
    beforeDestroy() {
        document.removeEventListener("swUpdated", this.showRefreshApp);
    }
};
</script>
