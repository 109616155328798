import gql from 'graphql-tag';

export const notificationsSeen = gql`
    mutation notificationsSee {
        notificationsSee {
            success,
            message
        }
    }
`;
