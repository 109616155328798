<template>
    <div id="app">
        <v-app>
            <install-prompt v-if="showInstallPromptAndroid" />
            <is-offline-message v-if="appOnline === false" />
            <is-online-message v-if="appOnline" />
            <page-transition v-if="!isLoading">
                <router-view />
            </page-transition>
            <loading v-if="isLoading" />
            <modal-install-prompt @closeInstallPrompt="showInstallPromptIOS = false" v-if="showInstallPromptIOS" />
            <refresh-app />
            <v-alert type="error" class="mt-2" v-if="isError">{{ errorText }}</v-alert>
        </v-app>
    </div>
</template>

<script>
import "./assets/scss/main.scss"; // Main styles
import PageTransition from "./components/Transitions/PageTransition";
import ModalInstallPrompt from "./components/UI/ModalInstallPrompt";
import { isInStandaloneMode, isIos, isIpad, shouldShowInstallPrompts } from "./helpers/helpers";
import { VApp } from "vuetify/lib";
import IsOfflineMessage from "./components/UI/IsOfflineMessage";
import InstallPrompt from "./components/UI/InstallPrompt";
import IsOnlineMessage from "./components/UI/isOnlineMessage";
import RefreshApp from "./components/UI/RefreshApp";

export default {
    data() {
        return {
            showInstallPromptAndroid: false,
            showInstallPromptIOS: false,
            isLoading: false,
            appOnline: navigator.onLine,
            isError: false,
            errorText: null,
            roleRefreshInterval: null
        };
    },
    components: {
        RefreshApp,
        IsOnlineMessage,
        InstallPrompt,
        IsOfflineMessage,
        VApp,
        PageTransition,
        ModalInstallPrompt
    },
    methods: {
        updateOnlineStatus(e) {
            const { type } = e;
            if (type === "online") {
                this.appOnline = true;
            }
            if (type === "offline") {
                this.appOnline = false;
            }
            console.trace('updateOnlineStatus', e)
        },
        getClient() {
            this.isLoading = true;

            this.$store.dispatch("client/getCurrentClient").then((data) => {
                this.$vuetify.theme.themes.light.primary = data.client.color || localStorage.getItem("THEME_COLOR");
                this.isLoading = false;
            }).catch((err) => {
                this.isError = true;
                this.errorText = err;
                this.isLoading = false;
            });
        },
        getCurrentClientFromStorage() {
            const currentClientLogo = localStorage.getItem("THEME_LOGO");
            const currentClientColor = localStorage.getItem("THEME_COLOR");
            return !!(currentClientColor || currentClientLogo);
        },
        loadRoleData() {
            if (!this.appOnline) {
                console.log("loadRoleData app is offline");

                return;
            }
            if (!this.$store.state.users.currentUser?.id) {
                console.log("loadRoleData no user id");

                return;
            }
            console.log("loadRoleData refreshing");

            return this.$store.dispatch("users/refreshUserRole");
        }
    },
    created() {
        if (!this.getCurrentClientFromStorage()) {
            this.getClient();
        }

        if ((isIos() || isIpad()) && !isInStandaloneMode() && shouldShowInstallPrompts()) {
            this.showInstallPromptIOS = true;
        }

        if (shouldShowInstallPrompts()) {
            this.showInstallPromptAndroid = true;
        }
        window.addEventListener("online", this.updateOnlineStatus);
        window.addEventListener("offline", this.updateOnlineStatus);
        this.$store.commit("changeLanguage", this.$i18n.locale);
        this.interval = setInterval(function() {
            this.loadRoleData();
        }.bind(this), 3600 * 1000);
    },
    beforeDestroy() {
        window.removeEventListener("online", this.updateOnlineStatus);
        window.removeEventListener("offline", this.updateOnlineStatus);
        clearInterval(this.roleRefreshInterval);
    }
};
</script>
