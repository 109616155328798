
export const state = {
    showModalSurveyCreated: false,
    onShowLockedSurveyModal: false,
    onShowCompletedSurveyModal: false
};

export const getters = {
};

const mutations = {
    setModal(state, payload) {
        state[payload] = !state[payload];
    },
    closeModal(state) {
        Object.keys(state).forEach(key => {
            state[key] = false;
        });
    }
};

const actions = {
    toggleModal({commit}, val) {
        commit('setModal', val);
    },
    closeModal({commit}) {
        commit('closeModal');
    }
};

export default {
    state,
    mutations,
    actions,
    getters
};
