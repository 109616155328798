import {
    getCompletedSurveysFromIndexedDB,
    getServerPendingSurveysFromIndexedDB,
    getSurveysFromIndexedDB,
    saveCompletedSurveysToIndexedDB,
    savePendingSurvey,
    saveServerPendingSurveysToIndexedDB,
    saveSurveyToIndexedDB,
} from "../../helpers/indexedDB";
import { apolloClient } from "../../apollo/apollo";
import {
    getSurveyByVersion,
    getSurveyInstance,
    getSurveyInstancesByType,
    getSurveyInstanceVersion,
    getSurveys,
} from "../../graphql/queries/surveys";
import {
    submitAllFilesToSurvey,
    submitSurvey,
} from "../../graphql/mutations/surveys";
import { generateUniqueID } from "../../helpers/helpers";

export const state = {
    surveyVersions: [],
    surveys: [],
    currentSurvey: null,
    currentSurveyAnswers: [],
    filesToQuestions: [],
    tempSurveyId: null,
    completedSurveys: [],
    pendingSurveys: [],
    surveyMode: null,
    isOnline: navigator.onLine,
};

export const getters = {
    surveysIMO: (state) => {
        if (!state.isOnline) {
            const surveys = [];
            state.surveys.filter((survey) => {
                const catName = survey.survey.category.name.en;
                const surveyObject = survey.survey;
                if (catName === "IMO") {
                    surveys.push(surveyObject);
                }
            });
            return surveys;
        } else {
            if (state.surveyVersions) {
                return state.surveyVersions.filter((survey) => {
                    if (survey.category) {
                        return survey.category.name.en === "IMO";
                    }
                });
            }
        }
    },
    surveysNoneIMO: (state) => {
        if (!state.isOnline) {
            const surveys = [];
            state.surveys.filter((survey) => {
                const catName = survey.survey.category.name.en;
                const surveyObject = survey.survey;
                if (catName === "Non-IMO") {
                    surveys.push(surveyObject);
                }
            });
            return surveys;
        } else {
            if (state.surveyVersions) {
                return state.surveyVersions.filter(
                    (survey) => survey.category.name.en === "Non-IMO"
                );
            }
        }
    },
    surveysOther: (state) => {
        if (!state.isOnline) {
            const surveys = [];
            state.surveys.filter((survey) => {
                const catName = survey.survey.category.name.en;
                const surveyObject = survey.survey;
                if (catName === "Other") {
                    surveys.push(surveyObject);
                }
            });
            return surveys;
        } else {
            if (state.surveyVersions) {
                return state.surveyVersions.filter(
                    (survey) => survey.category.name.en === "Other"
                );
            }
        }
    },
    questionCount: (state) => {
        if (
            state.currentSurvey &&
            state.currentSurvey.survey &&
            state.currentSurvey.survey.children.length > 0
        ) {
            const questions = [];
            state.currentSurvey.survey.children.forEach((page) => {
                page.children.forEach((section) => {
                    section.children.forEach((questionItem) => {
                        if (
                            questionItem &&
                            questionItem.isVisible &&
                            questionItem.isRequired
                        ) {
                            questions.push(questionItem);
                        }
                    });
                });
            });
            return questions.length;
        }
    },
    answeredQuestionsCount: (state) => {
        return state.currentSurveyAnswers.length;
    },
    currentQuestions: (state) => {
        let questions = {};
        if (state.currentSurvey  && state.currentSurvey.survey && state.currentSurvey.survey.children) {
            state.currentSurvey.survey.children.forEach((page) => {
                page.children.forEach((section) => {
                    section.children.forEach((question) => {
                        questions[question.id] = question;
                    })
                })
            });
        }
        return questions;
    },
    getCurrentSurveyWithRoles: (state, getters, rootState) => {
        let currentSurvey = JSON.parse(JSON.stringify(state.currentSurvey));
        let role = rootState.users.currentUser.roleName || "crew";
        let filteredPages = [];
        currentSurvey.survey.children.forEach((page) => {
            if (!page.roleName || page.roleName.length === 0) {
                page.roleName = ["crew"];
            }
            let filteredSections = [];
            page.children.forEach((section) => {
                if (!section.roleName || section.roleName.length === 0) {
                    section.roleName = [...page.roleName];
                }
                let filteredQuestions = [];
                section.children.forEach((question) => {
                    if (!question.roleName || question.roleName.length === 0) {
                        question.roleName = [...section.roleName];
                    }
                    console.log(role, {
                        title: question.index,
                        roles: question.roleName,
                        included: question.roleName.includes(role),
                    });

                    if (question.roleName.includes(role)) {
                        filteredQuestions.push(question);
                    }
                });

                console.log({ filteredQuestions });

                section.children = filteredQuestions;

                console.log(role, {
                    title: section.index,
                    roles: section.roleName,
                    included: section.roleName.includes(role),
                    emptyChildren: filteredQuestions.length === 0,
                });
                if (filteredQuestions.length === 0) {
                    return false;
                } else {
                    if (
                        section.roleName.length === 1 &&
                        section.roleName[0] === "crew"
                    ) {
                        filteredSections.push(section);
                    } else {
                        if (section.roleName.includes(role)) {
                            filteredSections.push(section);
                        }
                    }
                }
            });
            page.children = filteredSections;

            console.log(role, {
                title: page.index,
                roles: page.roleName,
                included: page.roleName.includes(role),
                emptyChildren: filteredSections.length === 0,
            });
            if (filteredSections.length === 0) {
                return false;
            } else {
                if (page.roleName.length === 1 && page.roleName[0] === "crew") {
                    filteredPages.push(page);
                } else {
                    if (page.roleName.includes(role)) {
                        filteredPages.push(page);
                    }
                }
            }
        });
        console.log({ filteredPages });

        currentSurvey.survey.children = filteredPages;
        return currentSurvey;
    },
};

export const mutations = {
    setSurveyMode(state, mode) {
        state.surveyMode = mode;
    },
    setTempSurveyId(state, id) {
        if (id) {
            state.tempSurveyId = id;
        } else {
            state.tempSurveyId = generateUniqueID();
        }
    },
    updateFilesToQuestion(state, payload) {
        let currentQuestion;
        let currentQuestionIndex;
        state.filesToQuestions.forEach((question, index) => {
            if (question.questionId === payload.questionId) {
                if (
                    !question.optionId ||
                    question.optionId === payload.optionId
                ) {
                    currentQuestion = question;
                    currentQuestionIndex = index;
                }
            }

            if (index === state.filesToQuestions.length - 1) {
                const updatedQuestion = {
                    ...currentQuestion,
                    answerId: payload.answerId,
                    instanceId: payload.instance,
                    path: payload.path,
                    fileType: payload.fileType,
                    optionId: payload.optionId,
                };

                // remove old value
                state.filesToQuestions.splice(currentQuestionIndex, 1); // remove found value

                // Add value
                state.filesToQuestions.push(updatedQuestion);
            }
        });
    },
    setFilesToQuestion(state, files) {
        let fileIndex;
        if (state.filesToQuestions.length > 0) {
            state.filesToQuestions.forEach((fileItem, index) => {
                if (fileItem.questionId === files.questionId) {
                    fileIndex = index;
                    state.filesToQuestions.splice(fileIndex, 1);
                    state.filesToQuestions.push(files);
                } else {
                    if (index === state.filesToQuestions.length - 1) {
                        state.filesToQuestions.push(files);
                    }
                }
            });
        } else {
            state.filesToQuestions.push(files);
        }
    },
    setFilesFromIndexedDBToQuestion(state, files) {
        state.filesToQuestions = files;
    },
    setSurveysVersions(state, data) {
        state.surveyVersions = data;
    },
    setCurrentSurvey(state, survey) {
        state.currentSurvey = null;
        state.currentSurvey = survey;
    },
    setAddSurvey(state, payload) {
        const survey = payload;
        if (!state.surveys.includes(survey)) {
            state.surveys.push(survey);
            saveSurveyToIndexedDB(survey);
        } else {
            console.log("survey already exist, no need to push");
        }
    },
    setAddSurveyFromStorage(state, payload) {
        const survey = payload;
        if (!state.surveys.includes(survey)) {
            state.surveys.push(survey);
        } else {
            console.log("survey already exist, no need to push");
        }
    },
    addOrUpdateSurveyAnswer(state, answer) {
        const answerId = answer.id;
        let currentAnswerToQuestionIndex = -1;
        let questions = getters.currentQuestions(state);
        console.log(questions, questions[answerId]);
        if(questions[answerId]?.inputType === 'SHIPNAME') {
            state.currentSurvey.survey.ship = answer.value;
        }
        state.currentSurveyAnswers.forEach((element, index) => {
            if (element[answerId] !== undefined) {
                currentAnswerToQuestionIndex = index;
            }
        });

        if (currentAnswerToQuestionIndex !== -1) {
            state.currentSurveyAnswers.splice(currentAnswerToQuestionIndex, 1);
            state.currentSurveyAnswers.push({ [answer.id]: answer.value });
        } else {
            state.currentSurveyAnswers.push({
                [answer.id]: answer.value,
            });
        }

        if (
            state.surveyMode !== "resubmit-survey" &&
            state.currentSurveyAnswers.length > 0
        ) {
            savePendingSurvey(
                state.tempSurveyId,
                state.currentSurvey,
                state.currentSurveyAnswers,
                state.filesToQuestions
            );
        }
    },
    savePendingSurvey() {
        if (
            state.surveyMode !== "resubmit-survey" &&
            state.currentSurveyAnswers.length > 0
        ) {
            savePendingSurvey(
                state.tempSurveyId,
                state.currentSurvey,
                state.currentSurveyAnswers,
                state.filesToQuestions
            );
        }
    },
    deleteQuestionFromSurveyAnswers(state, id) {
        state.currentSurveyAnswers = state.currentSurveyAnswers.filter(
            (element) => {
                return !element[id];
            }
        );
        if (state.surveyMode !== "resubmit-survey") {
            savePendingSurvey(
                state.tempSurveyId,
                state.currentSurvey,
                state.currentSurveyAnswers,
                state.filesToQuestions
            );
        }
    },
    setCompletedSurveys(state, payload) {
        state.completedSurveys = payload;
        saveCompletedSurveysToIndexedDB(payload);
    },
    setPendingSurveys(state, payload) {
        state.pendingSurveys = [...payload];
        saveServerPendingSurveysToIndexedDB(payload);
    },
    setSurveyAnswers(state, answers) {
        state.currentSurveyAnswers = [...answers];
    },
    clearSurveyAnswers(state) {
        localStorage.removeItem("surveyAnswers");
        state.currentSurveyAnswers = null;
        state.currentSurveyAnswers = [];
    },
    clearSurveyFiles(state) {
        state.filesToQuestions = null;
        state.filesToQuestions = [];
    },
};

export const actions = {
    async getSurveys({ commit }) {
        const { data } = await apolloClient.query({
            query: getSurveys,
            fetchPolicy: "no-cache",
        });

        try {
            commit("setSurveysVersions", data.surveys);
            return data.surveys;
        } catch (error) {
            console.error("error happened in getSurveys", error);
            return error;
        }
    },
    async getSurveyByVersion({ commit, state }, survey) {
        const { data } = await apolloClient.query({
            query: getSurveyByVersion,
            fetchPolicy: "no-cache",
            variables: {
                id: survey.id,
                version: survey.version,
            },
        });
        try {
            const surveyJSON = JSON.parse(data.surveyVersion);
            commit("setCurrentSurvey", surveyJSON);
        } catch (e) {
            console.error("error in getSurveyByVersion", e);
            return e;
        }
    },
    async getInitialSurveyByVersion({ commit, state }, survey) {
        const { data } = await apolloClient.query({
            query: getSurveyByVersion,
            fetchPolicy: "no-cache",
            variables: {
                id: survey.id,
                version: survey.version,
            },
        });
        try {
            commit("setAddSurvey", JSON.parse(data.surveyVersion));
        } catch (e) {
            console.error("error in getSurveyById", e);
            return e;
        }
    },
    async submitSurvey({ commit, state, rootState }, payload) {
        const { data } = await apolloClient.mutate({
            mutation: submitSurvey,
            variables: {
                id: state.currentSurvey.survey.id,
                version: state.currentSurvey.survey.version,
                answers: JSON.stringify(state.currentSurveyAnswers),
                locale: rootState.locale,
                instanceId: payload ? payload.instanceId : null, // Only used in resubmit - (pending surveys)
                instanceVersion: payload ? payload.instanceVersion : null, // Only used in resubmit - (pending surveys)
            },
        });

        try {
            return data;
        } catch (e) {
            console.error("error in submitSurvey", e);
            return e;
        }
    },
    async submitFilesToSurvey({ commit }, payload) {
        const { data } = await apolloClient.mutate({
            mutation: submitAllFilesToSurvey,
            variables: {
                instanceId: payload.instanceId,
                answerId: payload.answerId,
                fileName: payload.fileName,
                fileType: payload.type,
                optionId: payload.optionId || null,
            },
        });

        try {
            return data;
        } catch (e) {
            alert("error in submitting file to survey" + e);
            return e;
        }
    },
    async getSurveysByStatus({ commit }, type) {
        const { data } = await apolloClient.query({
            query: getSurveyInstancesByType,
            variables: {
                type: type,
            },
            fetchPolicy: "no-cache",
        });

        try {
            if (type === "completed") {
                commit("setCompletedSurveys", data.surveyInstances);
            }
            if (type === "pending") {
                commit("setPendingSurveys", data.surveyInstances);
            }
        } catch (e) {
            console.error("error in getSurveysByStatus", e);
            return e;
        }
    },
    async getSurveyInstanceByVersion({ commit }, survey) {
        const { data } = await apolloClient.query({
            query: getSurveyInstanceVersion,
            fetchPolicy: "no-cache",
            variables: {
                instanceId: survey.id,
                version: survey.version,
            },
        });
        try {
            return data.surveyInstanceVersion;
        } catch (e) {
            console.error("error in getSurveyInstanceByVersion", e);
            return e;
        }
    },
    async getSurveyInstance({ commit }, id) {
        const { data } = await apolloClient.query({
            query: getSurveyInstance,
            fetchPolicy: "no-cache",
            variables: {
                id: id,
            },
        });
        try {
            return data.surveyInstance;
        } catch (e) {
            console.error("error in getSurveyInstance", e);
            return e;
        }
    },
    async getPendingSurveysFromIndexedDB({ commit }) {
        const response = await getServerPendingSurveysFromIndexedDB();
        try {
            const surveys = response[0].surveys;
            commit("setPendingSurveys", surveys);
        } catch (e) {
            console.error("cant get pending surveys from storage", e);
        }
    },
    async getCompletedSurveysFromIndexedDB({ commit }) {
        const response = await getCompletedSurveysFromIndexedDB();
        try {
            console.log("response", response);
            const surveys = response[0].surveys;
            commit("setCompletedSurveys", surveys);
        } catch (e) {
            console.error("cant get pending surveys from storage", e);
        }
    },
    async getSurveysFromStorage({ commit }) {
        const response = await getSurveysFromIndexedDB();
        try {
            response.forEach((survey) => {
                commit("setAddSurveyFromStorage", survey);
            });
        } catch (e) {
            console.error("error in getSurveysFromStorage", e);
            return e;
        }
    },
};

export default {
    state,
    mutations,
    actions,
    getters,
};
