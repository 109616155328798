export default function (value) {
    let status;
    switch (value) {
        case "UNSUBMITTED":
            status = "status.unsubmitted";
            break;
        case "OPEN":
            status = "status.open";
            break;
        case "CLOSED":
            status = "status.closed";
            break;
        case "PARTIALLY_SUBMITTED":
            status = "status.partially_submitted";
            break;
        case "SUBMITTED":
        default:
            status = "status.submitted";
    }
    return status;
}
