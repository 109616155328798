import db from '../plugins/db';

const dbAvailable = true;
export const saveSurveyToIndexedDB = (survey) => {
    db.open().then(() => {
        db.surveys
            .add(survey)
            .then((response) => {
                // console.log("done adding survey to db");
            })
            .then(() => {
                return db.surveys.toArray();
            })
            .catch((e) => {
                console.error('error in adding surveys', e);
            });
    });
};

export const saveCompletedSurveysToIndexedDB = (surveys) => {
    db.open().then(() => {
        const payload = {
            id: 'completedSurveys',
            surveys: surveys,
        };
        db.completedSurveys
            .put(payload)
            .then((response) => {
                console.log('done adding completed surveys to db');
            })
            .catch((e) => {
                console.error('error in adding completed surveys', e);
            });
    });
};

export const saveServerPendingSurveysToIndexedDB = (surveys) => {
    db.open().then(() => {
        const payload = {
            id: 'pendingSurveys',
            surveys: surveys,
        };
        db.pendingSurveyFromServer
            .put(payload)
            .then((response) => {
                console.log('done adding server pending surveys to db');
            })
            .catch((e) => {
                console.error('error in adding pending surveys', e);
            });
    });
};

export const saveNotificationsIndexedDB = (notifications) => {
    db.open().then(() => {
        const payload = {
            id: 'notifications',
            notifications: notifications,
        };
        db.notifications
            .put(payload)
            .then((response) => {
                console.log('done adding notifications to db');
            })
            .catch((e) => {
                console.error('error in adding notifications', e);
            });
    });
};

export const saveStaticPagesIndexedDB = (pages) => {
    db.open().then(() => {
        const payload = {
            id: 'pages',
            pages: pages,
        };
        db.staticPages
            .put(payload)
            .then((response) => {
                console.log('done adding pages to db');
            })
            .catch((e) => {
                console.error('error in adding pages', e);
            });
    });
};

export const getSurveysFromIndexedDB = () => {
    return db.surveys.toArray();
};

export const getCompletedSurveysFromIndexedDB = () => {
    return db.completedSurveys.toArray();
};

export const getServerPendingSurveysFromIndexedDB = () => {
    return db.pendingSurveyFromServer.toArray();
};

export const getNotificationsFromIndexedDB = () => {
    // return db.notifications.toArray;
    return db.notifications.toArray();
};

export const getStaticPagesFromIndexedDB = () => {
    return db.staticPages.toArray();
};

export const clearSurveysFromDB = () => {
    db.surveys.clear();
};
/**
 *
 * @param id
 * @param currentSurvey
 * @param surveyAnswers
 * @param surveyFiles Array<File>
 * @returns {Promise<void>}
 */
export const savePendingSurvey = async(
    id,
    currentSurvey,
    surveyAnswers,
    surveyFiles
) => {
    if (currentSurvey.survey?.status === 'PARTIALLY_SUBMITTED') {
        return;
    }
    try {
        console.log(surveyFiles);
        // await deletePendingSurveyByID(id);
        const updatedSurvey = {
            id: id,
            createdAt: new Date(),
            status: 'UNSUBMITTED',
            surveyVersion: currentSurvey.survey.version,
            currentSurvey: currentSurvey.survey,
            currentSurveyAnswers: surveyAnswers,
            currentSurveyFiles: surveyFiles,
        };
        await db.pendingSurveys
            .put(updatedSurvey)
            .then((response) => {
                console.info('done adding pending survey', response);
            })
            .catch('InvalidStateError', (e) => {
                // Failed with InvalidStateError
                window.alert('InvalidState error: ' + e.message);
            })
            .catch((error) => {
                if (
                    error.name === 'QuotaExceededError' ||
                    (error.inner && error.inner.name === 'QuotaExceededError')
                ) {
                    // QuotaExceededError may occur as the inner error of an AbortError
                    window.alert(
                        'Quota exceeded error! - It seems there are not enough space available on your device for us to save the survey. Please free up more space by clearing all browser cache, or removing some of your old drafts, or close app and try again'
                    );
                } else if (error.name === 'AbortError') {
                    if (error.inner) {
                        window.alert('Abort error: ' + error.inner);
                    } else {
                        window.alert('Abort error: ' + error);
                    }
                } else {
                    // Any other error derived from standard Error
                    if (error.message.toString().includes('Failed to putOrAdd')) {
                        window.alert(
                            'Cant save survey to the Local Database. ' +
                            'There are not enough available space to your local database to save the survey as a draft. ' +
                            'Please free up more space ' +
                            'by clearing all browser cache, or removing some of your old drafts, or close app and try again'
                        );
                    } else {
                        // Any other error
                        window.alert(
                            'Cant save your survey: ' +
                            error +
                            ' - ' +
                            error.inner.name +
                            ' - Please try closing the app and clear your cache, and try again'
                        );
                    }
                }
            });
    } catch (e) {
        console.error('catch error in save pending survey', e);
        // alert("error happened - please try again");
    }
};

export const deletePendingSurveyByID = (id) => {
    return db.pendingSurveys.delete(id);
};

export const getPendingSurveysFromDB = async() => {
    const response = await db
        .open()
        .then(() => {
            return db.pendingSurveys.toArray();
        })
        .then((surveys) => {
            const tempSurveys = [];
            surveys.forEach((survey) => {
                tempSurveys.push({
                    ...survey.currentSurvey,
                    createdAt: survey.createdAt,
                    status: survey.status,
                    tempIdFromDB: survey.id,
                });
            });
            return tempSurveys;
        });

    try {
        return response;
    } catch (e) {
        alert('error getting pending surveys ' + e);
    }
};

export const getPendingSurveyByIDFromDB = async(tempId) => {
    const response = db
        .open()
        .then(() => {
            return db.pendingSurveys.where('id').equals(tempId).toArray();
        })
        .then((survey) => {
            return survey;
        });

    try {
        return response;
    } catch (e) {
        console.error('error', e);
        alert('error getting your unsubmitted survey ' + e);
    }
};
