import {register} from 'register-service-worker';

let timeout = null;
if (process.env.NODE_ENV === 'production') {
    register(`${process.env.BASE_URL}service-worker.js`, {
        ready() {
            console.log('Service worker is active.');
        },
        registered(registration) {
            console.log('Service worker has been registered.');
            // Routinely check for app updates by testing for a new service worker.

            const handler = function registeredHandler() {
                registration.update().then(() => {
                    console.log('Checked for updates.');
                    timeout = setTimeout(handler, 60 * 60 * 1000);
                });
            };
            timeout = setTimeout(handler, 60 * 60 * 1000);
        },
        cached() {
            console.log('Content has been cached for offline use.');
        },
        updatefound() {
            console.log('New content is downloading.');
        },
        updated(registration) {
            console.log('New content is available; please refresh.');

            // Add a custom event and dispatch it.
            // Used to display of a 'refresh' banner following a service worker update.
            // Set the event payload to the service worker registration object.
            document.dispatchEvent(
                new CustomEvent('swUpdated', {detail: registration})
            );
            if (timeout) {
                clearTimeout(timeout);
            }
        },
        offline() {
            console.log('No internet connection found. App is running in offline mode.');
        },
        error(error) {
            console.error('Error during service worker registration:', error);
        }
    });
}
