<template>
    <v-dialog
        v-model="dialog"
        max-width="360"
    >
        <v-card>
            <v-card-title class="headline text-center d-block">{{$t('ios_install_prompt.title')}}</v-card-title>

            <v-card-text class="text-center">
                <v-icon size="70" class="mb-2" color="primary">get_app</v-icon>
                <p>{{$t("ios_install_prompt.description")}}</p>

                    <i18n path="ios_install_prompt.description_with_icon" tag="p">
                        <v-icon size="25" color="primary" style="transform: rotate(-90deg)">exit_to_app</v-icon>
                    </i18n>
                <v-btn class="mt-2 mx-auto" color="primary" outlined rounded @click="onCloseModal">{{$t('basic.close_window')}}</v-btn>
            </v-card-text>

        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'ModalInstallPrompt',
    data: () => ({
        dialog: true
    }),
    methods: {
        onCloseModal() {
            this.$emit('closeInstallPrompt');
            this.$store.dispatch('modals/closeModal');
        }
    }
};
</script>
