<template>
    <v-alert v-model="showInstaller" tile type="info" close-label="Close" prominent dismissible dense class="mb-0">
        <div>
            <p class="mb-1">
                {{$t('install_prompt.body')}}
                </p>
            <v-btn depressed tile light @click="installer">{{$t("install_prompt.btn")}}</v-btn>
        </div>
    </v-alert>
</template>

<script>
import {mapState} from 'vuex';

export default {
    name: 'InstallPrompt',
    data: () => ({
        showInstaller: false,
        installer: undefined
    }),
    computed: {
        ...mapState('client', ['currentClient'])
    },
    created() {
        let installPrompt;

        window.addEventListener('beforeinstallprompt', (e) => {
            e.preventDefault();
            installPrompt = e;
            console.log(e.platforms);
            this.showInstaller = e.platforms.includes('android');
        });

        this.installer = () => {
            installPrompt.prompt();

            installPrompt.userChoice.then((choiceResult) => {
                if (choiceResult.outcome === 'accepted') {
                    console.log('User accepted the A2HS prompt');
                } else {
                    console.log('User dismissed the A2HS prompt');
                }
                installPrompt = null;
                this.showInstaller = false;
            });
        };
    }
};
</script>
