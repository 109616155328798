export function createMixin(window) {
    return {
        data() {
            return {screen$: {width: 0, height: 0}};
        },
        created() {
            const htmlElement = window.document.querySelector('html');
            window.onresize = () => {
                this.screen$.width = htmlElement.clientWidth;
                this.screen$.height = htmlElement.clientHeight;
            };
            this.screen$.width = htmlElement.clientWidth;
            this.screen$.height = htmlElement.clientHeight;
        }
    };
}
