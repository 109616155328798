<template>
    <div class="app-view">
        <v-container class="pt-0 mt-0">
            <div class="logo text-center pt-10" v-if="logo">
                <img :src="logoPath + logo" v-if="logo" class="my-3 mx-auto" style="max-height:100px;"/>
            </div>

            <div class="py-4 text-center">
                <p class="my-0 py-0">{{ $t('signin.header_top') }}</p>
            </div>

            <form-sign-in/>

            <div class="text-center py-3 helper-links">
                <div>
                    <v-btn color="primary" :to="{name: 'request-access'}" class="u-text-normal" text>
                        {{ $t("signin.request_access") }}
                    </v-btn>
                </div>
                <div>
                    <v-btn color="primary" class="u-text-normal" text @click="dialog = true">
                        {{ $t('signin.forgot_password') }}
                    </v-btn>
                    <v-dialog @keydown.esc="dialog = false" v-model="dialog" max-width="290">
                        <v-card flat v-if="step === 1 && !isError">
                            <v-card-title>{{ $t('signin.forgot_password_form.email') }}</v-card-title>
                            <v-card-text class="pb-0">
                                <p>
                                    {{ $t("signin.forgot_password_form.description") }}
                                </p>
                                <v-form>
                                    <v-text-field
                                        v-model="user.email"
                                        label="Email"
                                        :placeholder='$t("signin.forgot_password_form.email_placeholder")'
                                    ></v-text-field>
                                </v-form>
                            </v-card-text>
                            <v-card-actions>
                                <v-btn class="mx-auto mb-2" color="primary" :loading="isLoading" outlined rounded
                                       @click="onSendPassword">{{ $t("signin.forgot_password_form.send_password") }}
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                        <v-card flat v-if="step === 2 && !isError">
                            <v-card-title>{{ $t("signin.forgot_password_submitted.title") }}</v-card-title>
                            <v-card-text>
                                <p>{{ $t("signin.forgot_password_submitted.description") }}</p>
                            </v-card-text>
                            <v-card-actions>
                                <v-btn class="mx-auto mb-2" color="primary" outlined rounded @click="onClose">
                                    {{ $t("basic.close_window") }}
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                        <v-card flat v-if="isError">
                            <v-card-text class="pt-10">
                                <v-alert type="error" dense icon="error_outline">
                                    {{ errorText }}
                                </v-alert>
                            </v-card-text>
                        </v-card>
                    </v-dialog>
                </div>
            </div>
        </v-container>
    </div>
</template>

<script>
import {mapState} from 'vuex';
import FormSignIn from '../components/Forms/FormSignIn';
import constants from '../helpers/constants';

export default {
    name: 'SignIn',
    data() {
        return {
            step: 1,
            logoPath: constants.cloudinaryUrl,
            logo: null,
            user: {
                email: null
            },
            rememberUser: false,
            dialog: false,
            isLoading: false,
            isError: false,
            errorText: '',
            languages: [
                {flag: 'us', language: 'en'}, {flag: 'dk', language: 'da'}, {flag: 'no', language: 'no'},
                {flag: 'de', language: 'de'}, {flag: 'se', language: 'sv'}, {flag: 'pt', language: 'pt'},
                {flag: 'br', language: 'pt_BR'}, {flag: 'es', language: 'es'}, {flag: 'ru', language: 'ru'}
            ]
        };
    },
    computed: {
        ...mapState('users', ['currentUser']),
        ...mapState('client', ['currentClient'])
    },
    components: {
        FormSignIn
    },
    methods: {
        onClose() {
            this.step = 1;
            this.dialog = false;
        },
        onSendPassword() {
            this.isLoading = true;
            this.$store.dispatch('users/onForgotPassword', this.user.email).then(() => {
                this.isLoading = false;
                this.step = 2;
            }).catch((err) => {
                this.isLoading = false;
                this.isError = false;
                this.errorText = err;
            });
        },
        changeLocale(locale) {
            this.$i18n.locale = locale;
        }
    },
    beforeCreate() {
        const userInStorage = localStorage.getItem('currentUser');
        if (userInStorage) {
            this.$router.push('/home');
        }
    },
    created() {
        if (this.currentClient) {
            this.logo = this.currentClient.logo;
        } else {
            this.logo = localStorage.getItem('THEME_LOGO');
        }
    }
};
</script>

<style lang="scss">

.u-card-border {
    border: 1px solid #e8e8e8;
}

.u-rounded {
    border-radius: 10px;
}

.u-rounded--small {
    border-radius: 5px;
}

.u-switch-small,
.u-switch-small label {
    font-size: 1rem;
}

.u-switch-small {
    .v-label {
        top: 2px !important;
    }
}

.flags .flag .flag-icon {
    width: 4rem;
    height: 4rem;
}

</style>
