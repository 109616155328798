import 'material-design-icons-iconfont/dist/material-design-icons.css';
import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#1867c0',
                secondary: '#5CBBF6',
                tertiary: '#E57373',
                accent: '#005CAF'
            }
        }
    },
    icons: {
        iconfont: 'md'
    }
});
