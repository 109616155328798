import gql from 'graphql-tag';
import {LocalizedInputFragment} from '@/graphql/fragtments/localizedInput';

export const getStaticPages = gql`
    query staticPages {
        staticPages{
            id,
            title {  ... localizedFragment  }
            content {  ... localizedFragment  }
        }
    }
    ${LocalizedInputFragment}
`;

export const getStaticPageByID = gql`
    query staticPage($id: ID!) {
        staticPage(id: $id) {
            id,
            title {  ... localizedFragment  },
            content {  ... localizedFragment  }
        }
    }
    ${LocalizedInputFragment}
`;
