<template>
    <div class="text-center mx-auto pa-3">
        <v-progress-circular
            :size="50"
            color="primary"
            indeterminate
        ></v-progress-circular>
    </div>
</template>

<script>
export default {
    name: 'Loading'
};
</script>
