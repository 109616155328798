import gql from 'graphql-tag';

export const getNotifications = gql`
    query notifications($pageSize: Int, $after: String) {
        notifications(
            pageSize: $pageSize,
            after: $after
        ) {
            cursor,
            hasMore,
            notifications {
                id,
                title,
                body,
                seen,
                seenAt,
                createdAt,
                instanceId,
                instanceVersion,
                surveyId,
                surveyVersion
            }
        }
    }
`;
