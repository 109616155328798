import store from '../store/index';

export const clearUserStorage = () => {
    // clearInterval(window.refreshToken);
    sessionStorage.clear();
    localStorage.clear();
    store.state.users.currentUser = null;
};

export const createUserStorage = (data) => {
    localStorage.setItem('AUTH_TOKEN', data.message);
    localStorage.setItem('USER_ID', data.user.id);
    localStorage.setItem('currentUser', JSON.stringify(data.user));
};
