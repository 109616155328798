import Vue from "vue";
import { ApolloClient } from "apollo-client";
import { HttpLink } from "apollo-link-http";
import { setContext } from "apollo-link-context";
import { onError } from "apollo-link-error";
import { InMemoryCache } from "apollo-cache-inmemory";
import VueApollo from "vue-apollo";
import authHelpers from "../auth/auth";
import {
    chooseDBasedOnHostname,
    clientIdBasedOnHostname,
} from "../helpers/config-helpers";

let serverUrl = chooseDBasedOnHostname(window.location.hostname).trim("/");
const httpLink = new HttpLink({
    uri: serverUrl,
});
let clientId = clientIdBasedOnHostname(window.location.hostname);
let subdomain = window.location.hostname.split(".").slice(0, -2).join(".");

if (!clientId) {
    fetch(`${serverUrl}/clientId/${subdomain}`).then((response) => {
        let resp = response.json();
        clientId = resp.clientId;
    });
}
console.log("choosing db", serverUrl);
console.log("choosing clientId", clientId);

const authLink = setContext((_, { headers }) => {
    // return the headers to the context so httpLink can read them
    if (clientId) {
        return {
            headers: {
                clientId: clientId || "",
                token: localStorage.getItem("AUTH_TOKEN") || "",
                userId: localStorage.getItem("USER_ID") || "",
            },
        };
    } else {
        return {
            headers: {
                "x-subdomain": subdomain,
                token: localStorage.getItem("AUTH_TOKEN") || "",
                userId: localStorage.getItem("USER_ID") || "",
            },
        };
    }
});

// Error Handling
const errorLink = onError(({ graphQLErrors, networkError, response }) => {
    if (networkError && graphQLErrors) {
        if (graphQLErrors[0].message.includes("Not authenticated")) {
            authHelpers.logOutUser();
        }
    }

    if (networkError && networkError.statusCode === 401) {
        authHelpers.logOutUser();
    }

    if (graphQLErrors) {
        graphQLErrors.map(({ message, locations, path }) => {
            if (message.includes("Not authenticated")) {
                authHelpers.logOutUser();
            }
            console.log("message", message);
            console.log(
                `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
            );
        });
    }
    if (networkError) {
        console.log(`[Network error]: ${networkError}`);
        console.error(networkError);
    }
    if (response) {
        console.error(response);
    }
});

// Create the apollo client
export const apolloClient = new ApolloClient({
    link: authLink.concat(errorLink.concat(httpLink)),
    cache: new InMemoryCache(),
    connectToDevTools: true,
});

// Install the Vue plugin
Vue.use(VueApollo);

export const apolloProvider = new VueApollo({
    defaultClient: apolloClient,
    defaultOptions: {
        $loadingKey: "loading",
    },
});
