import Vue from 'vue';
import VueI18n from 'vue-i18n';
import I18nFilters from './filters/translate';

Vue.use(VueI18n);
Vue.use(I18nFilters);

function loadLocaleMessages() {
    const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
    const messages = {};
    locales.keys().forEach(key => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i);
        if (matched && matched.length > 1) {
            const locale = matched[1];
            messages[locale] = locales(key);
        }
    });
    return messages;
}

const messages = loadLocaleMessages();
const localeKeys = Object.keys(messages).map(locKey => new RegExp(`^${locKey}`));
let locale = window.localStorage.getItem('locale');
console.log('Locale from localStorage', {locale});
if (!locale) {
    locale = navigator.languages.find(locale => {
        console.log('Checking for the following locale', {locale});
        return localeKeys.filter((locReg) => locReg.test(locale)).length > 0;
    });
    console.log('Found the following locale', {locale});
}

if (!locale) {
    locale = 'en';
    console.log('Using fallback', {locale});
}
export default new VueI18n({
    locale,
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
    messages
});
