import {apolloClient} from '../../apollo/apollo';
import {getNotifications} from '../../graphql/queries/notifications';
import {notificationsSeen} from '../../graphql/mutations/notifications';
import {getNotificationsFromIndexedDB, saveNotificationsIndexedDB} from '../../helpers/indexedDB';

export const state = {
    notifications: []
};

export const getters = {
    newNotifications: state => {
        return state.notifications.filter(notification => notification.seen === false);
    },
    oldNotifications: state => {
        return state.notifications.filter(notification => notification.seen === true);
    }
};

export const mutations = {
    setNotifications(state, data) {
        state.notifications = data;
        saveNotificationsIndexedDB(data);
    },
    setNotificationsSeen(state) {
        state.notifications.forEach((notification) => {
            notification.seen = true;
        });
    }
};

export const actions = {
    async getAllNotifications({commit}) {
        const {data} = await apolloClient.query({
            query: getNotifications,
            variables: {
                pageSize: 40,
                after: null
            },
            fetchPolicy: 'no-cache'
        });

        try {
            commit('setNotifications', data.notifications.notifications);
            return data;
        } catch (e) {
            console.error('error in getAllNotificaions', e);
            return e;
        }
    },
    async getAllNotificationsFromIndexedDB({commit}) {
        const response = await getNotificationsFromIndexedDB();
        console.log('response', response);
        try {
            console.log('in try');
            const notifications = response[0].notifications;
            console.log('notification', notifications);
            commit('setNotifications', notifications);
        } catch (e) {
            console.error('cant get notifications from indexedDB', e);
        }
    },
    async notificationsHasBeenSeen({commit}) {
        const {data} = await apolloClient.mutate({
            mutation: notificationsSeen
        });

        try {
            return data;
        } catch (e) {
            console.error('error on notificationsHasBeenSeen', e);
            return e;
        }
    }
};

export default {
    state,
    mutations,
    actions,
    getters
};
