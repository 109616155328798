<template>
    <div>
        <v-form>
            <v-text-field
                ref="email"
                :label='$t("basic.login")'
                name="login"
                type="text"
                v-model="user.email"
                autofocus
            ></v-text-field>

            <v-text-field
                autocomplete="true"
                id="password"
                :label='$t("basic.password")'
                name="password"
                type="password"
                v-model="user.password"
            ></v-text-field>

            <v-alert type="error" icon="error_outline" v-if="isError">
                {{errorText}}
            </v-alert>

            <div class="text-center">
                <v-btn color="primary" :loading="isLoading" min-width="150" outlined rounded @click.prevent="onSignIn">{{$t('signin.button_text')}}</v-btn>
            </div>
        </v-form>
    </div>
</template>

<script>
export default {
    name: 'FormSignIn',
    data() {
        return {
            user: {
                email: null,
                password: null
            },
            isLoading: false,
            isError: null,
            errorText: ''
        };
    },
    methods: {
        onSignIn() {
            this.isLoading = true;
            this.$store.dispatch('users/onLoginUser', this.user).then(() => {
                this.isLoading = false;
                this.isError = false;
                this.$router.push('/home');
            }).catch((err) => {
                this.$refs.email.focus();
                this.isLoading = false;
                this.isError = true;
                this.errorText = err.graphQLErrors[0].message;
            });
        }
    }
};
</script>
