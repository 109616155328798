import {
    deleteUser,
    updateUserLocale,
    updateUserPassword,
    userForgotPassword,
    userLogin,
    userResetPassword,
} from "../../graphql/mutations/users";
import { apolloClient } from "../../apollo/apollo";
import { clearUserStorage, createUserStorage } from "../../helpers/authHelpers";
import { userRoleRefresh } from "@/graphql/queries/users";

const getUserFromStorage = localStorage.getItem("currentUser");

export const state = {
    currentUser: JSON.parse(getUserFromStorage) || null,
    users: [],
    searchQuery: "",
};

export const getters = {};

export const mutations = {
    setCurrentUser(state, data) {
        state.currentUser = data.user;
    },
    setCurrentUserInStorage(state, data) {
        createUserStorage(data);
    },
};

export const actions = {
    async onLoginUser({ commit }, user) {
        clearUserStorage();

        const { data } = await apolloClient.mutate({
            mutation: userLogin,
            variables: {
                email: user.email,
                password: user.password,
            },
        });

        try {
            commit("setCurrentUser", data.userLogin);
            commit("setCurrentUserInStorage", data.userLogin);
            commit("changeLanguage", data.userLogin?.user?.locale, {
                root: true,
            });
            return data;
        } catch (error) {
            console.error(
                "error happened in modules/users - FN: onLoginUser, ",
                error
            );
            return error;
        }
    },
    async refreshUserRole({ commit }) {
        const { data } = await apolloClient.query({
            query: userRoleRefresh,
        });

        try {
            commit("setCurrentUser", data);
            localStorage.setItem("currentUser", JSON.stringify(data.user));

            return data;
        } catch (error) {
            console.error(error);
        }
    },
    async onForgotPassword({ commit }, email) {
        try {
            const response = await apolloClient.mutate({
                mutation: userForgotPassword,
                variables: {
                    email: email,
                },
            });
            return response;
        } catch (e) {
            return e;
        }
    },
    async onResetPassword({ commit }, data) {
        try {
            const response = await apolloClient.mutate({
                mutation: userResetPassword,
                variables: {
                    email: data.email,
                    token: data.token,
                    password: data.password,
                },
            });
            return response;
        } catch (e) {
            return e;
        }
    },
    async onUpdatePassword({ commit }, payload) {
        const { data } = await apolloClient.mutate({
            mutation: updateUserPassword,
            variables: {
                email: payload.email,
                oldPassword: payload.oldPassword,
                newPassword: payload.newPassword,
            },
        });

        try {
            localStorage.setItem("AUTH_TOKEN", data.userChangePassword.message);
            return data;
        } catch (e) {
            console.error("cant update password", e);
            return e;
        }
    },
    async onDeleteUserAccount({ commit }, id) {
        const { data } = await apolloClient.mutate({
            mutation: deleteUser,
            variables: {
                id: id,
            },
        });

        try {
            clearUserStorage();
            return data;
        } catch (e) {
            console.error("cant delete user", e);
            return e;
        }
    },
    async onUpdateLocale({ commit }, newLocale) {
        const { data } = await apolloClient.mutate({
            mutation: updateUserLocale,
            variables: { locale: newLocale },
        });
        commit("changeLanguage", data.userUpdateLocale?.user?.locale, {
            root: true,
        });
        return data;
    },
};

export default {
    state,
    mutations,
    actions,
    getters,
};
