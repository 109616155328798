export const clearThemeStorage = () => {
    localStorage.removeItem('THEME_COLOR');
    localStorage.removeItem('THEME_LOGO');
};

export const setThemeStorage = (data) => {
    localStorage.setItem('THEME_COLOR', data.color);
    localStorage.setItem('THEME_LOGO', data.logo);
};

export const checkThemeStorage = () => {
    return !!localStorage.getItem('THEME_COLOR');
};
