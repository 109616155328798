const apolloConfig = {
    envUrls: {
        test: "https://athena-backend.test/",
        local: "https://planar-flux-246917.appspot.com/",
        staging: "https://athena-staging-293213.ew.r.appspot.com/",
        // local: 'https://athenasurveys.appspot.com',
        production: "https://athenasurveys.appspot.com/",
        //test: "https://athena-staging-293213.ew.r.appspot.com/",
    },
    clientId: "9OFGz6J5Parka5Iiv6Bs",
    // clientId: 'A2ptl2mpTiyHJneXCWfe',
    clientIdTokioMarine: "A2ptl2mpTiyHJneXCWfe",
    clientIdInsurwave: "6X582u0DGiW55t4KyZgu",
    clientIdSAC: "brDh9225UZEuZVU56vAc",
    clientIdFairway: "AHLMhTBNdDVhIrIoBcBx",
    clientIdTest: "brDh9225UZEuZVU56vAc",
};

export default apolloConfig;
