import gql from 'graphql-tag';

export const getCurrentClient = gql`
    query client{
        client{
            id
            name,
            subdomain,
            color,
            logo
        }
    }
`;

export const allClientsQuery = gql`
    query clients {
        client: client {
            id
            name,
            subdomain,
            color,
            logo
        }
    }
`;

// From ClientId in http header
export const getAllCompaniesFromClient = gql`
    query companies {
        companies {
            id,
            name,
            accountHolderName,
            accountHolderEmail,
            accountHolderPhone
        }
    }
`;
